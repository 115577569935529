import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function WebsiteVisitngCountriesTable(props:any) {
    const {t} = useTranslation()
    const {items}=props
  return (
    <div className='row p-5'>
        <hr/>
        <div className='table-responsive'>
            <h2 className='fw-bold'>{t('Visiting Countries')}</h2>

        <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
        <thead>
        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            <th>#</th>
            <th>{t('Name')}</th>
        </tr>
        </thead>
            <tbody className='text-gray-600 fw-bold' >
                {items?.length > 0 ?(
                    items?.map((item:any,index:any)=>(
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item}</td>
                    </tr>
                ))
                ) : (
                <tr>
                    <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {t('No matching records found')}
                    </div>
                    </td>
                </tr>
            )}
            </tbody>
            </table>
        </div>
        </div>
  )
}

export default WebsiteVisitngCountriesTable