import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function UsersTable(props:any) {
  const {t} =useTranslation()
  const {items}=props
  return (
    <div className='row p-5'>
        <hr/>
        <div className='table-responsive'>
            <h2 className=' fw-bold'>{t('Last 5 Users')}</h2>
        <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
        <thead>
        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            <th>#</th>
            <th>{t('Name')}</th>
            <th>{t('Phone Number')}</th>
            <th>{t('WhatsApp Number')}</th>
            <th>{t('Status')}</th>
            <th>{t('Created At')}</th>
        </tr>
        </thead>
            <tbody className='text-gray-600 fw-bold' >
                {items?.length > 0 ?(
                    items?.map((item:any,index:any)=>(
                    <tr key={index}>
                        <td>{item?.item_number}</td>
                        <td>
                            <Link to={`/apps/users/all`}>{item?.name?item?.name:'---'}</Link>
                        </td>
                        <td>{item?.phone_number?item?.phone_number:'---'}</td>
                        <td>{item?.whatsapp_number?item?.whatsapp_number:'---'}</td>
                        <td>
                            {
                                item?.status==2?
                                <label className='fs-5 text-success mb-0' htmlFor='UserEnglishName'>{t('Active')}</label>
                            :
                                item?.status==3?
                                <label className='fs-5 text-danger mb-0' htmlFor='UserEnglishName'>{t('Blocked')}</label>
                            :
                                <label className='fs-5 text-secondary mb-0' htmlFor='UserEnglishName'>{t('Not Completed Yet')}</label>
                            }
                        </td>
                        <td>{item?.created_at?item?.created_at:'---'}</td>
                    </tr>
                ))
                ) : (
                <tr>
                    <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {t('No matching records found')}
                    </div>
                    </td>
                </tr>
            )}
            </tbody>
            </table>
        </div>
    </div>
  )
}

export default UsersTable